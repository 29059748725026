let key = {};

if (process.env.REACT_APP_MODE === "production") {
  console.log = function () { };

  console.log("Set Production Config");
  const API_URL = "https://userapi.foruspf.com";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LfoMWopAAAAABTBQ2vyGfHWgJfa5E6CRcWzSQfm",
    API_URL: "https://userapi.foruspf.com",
    USERAPI_URL: "https://userapi.foruspf.com",
    WALLETAPI_URL: "https://walletapi.foruspf.com",
    SPOTAPI_URL: "https://spotapi.foruspf.com",
    P2PAPI_URL: "https://p2papi.foruspf.com",
    LAUNCHPADAPI_URL: "https://launchpadapi.foruspf.com",
    FRONT_URL: "https://www.foruspf.com",
    ADMIN_URL: "https://controls.foruspf.com",
    SOCKET_URL: "https://spotapi.foruspf.com",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    ON_RAMP: {
      API_KEY: 'pk_prod_l3AM9XremfSIgCuFykpgmEQtiwXs7UhlR9yq0wMT4M00'
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/forus",
      twitterUrl: "https://www.twitter.com/forus",
      linkedinUrl: "https://www.linkedin.com/forus",
      redditUrl: "https://www.reddit.com/forus",
      youtubeUrl: "https://www.youtube.com/forus",
    },
    exchangeurl: "https://ico.forus.exchange/home/",
    zaakpayurl: "",
    MerchantID: "",
    returnUrl: "",
    zaaksecretkey: "",
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set dev env Config");
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LfoMWopAAAAABTBQ2vyGfHWgJfa5E6CRcWzSQfm",
    API_URL: "https://forususerapi.wearedev.team",
    USERAPI_URL: "https://forususerapi.wearedev.team",
    WALLETAPI_URL: "https://foruswalletapi.wearedev.team",
    SPOTAPI_URL: "https://forusspotapi.wearedev.team",
    SOCKET_URL: "https://forusspotapi.wearedev.team",
    P2PAPI_URL: "https://forusp2papi.wearedev.team/",
    LAUNCHPADAPI_URL: "https://forusstakingapi.wearedev.team",
    FRONT_URL: "https://forus-frontend.pages.dev/",
    ADMIN_URL: "https://forus-adminpanel.pages.dev/",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE: "",
      APP_STORE: "",
    },
    ON_RAMP: {
      API_KEY:
        // 'pk_prod_01H8HS16EX7VPA7BBNNDN03DDH'
        'pk_prod_l3AM9XremfSIgCuFykpgmEQtiwXs7UhlR9yq0wMT4M00'
    },

    socialMedia: {
      telegramUrl: "https://telegram.org/forus",
      twitterUrl: "https://www.twitter.com/forus",
      linkedinUrl: "https://www.linkedin.com/forus",
      redditUrl: "https://www.reddit.com/forus",
      youtubeUrl: "https://www.youtube.com/forus",
    },
    exchangeurl: "",
    zaakpayurl: "",
    MerchantID: "",
    returnUrl: "",
    zaaksecretkey: "",
  };
} else {
  console.log("Set Development Configssssssssssssssssssssssssssssss");
  const API_URL = "http://localhost"; //http:/192.168.0.112:3000/  http://localhost
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LfoMWopAAAAABTBQ2vyGfHWgJfa5E6CRcWzSQfm",
    API_URL: `${API_URL}:3001`,
    USERAPI_URL: `${API_URL}:3001`,
    WALLETAPI_URL: `${API_URL}:3002`,
    SPOTAPI_URL: `${API_URL}:3003`,
    P2PAPI_URL: `${API_URL}:3004`,
    LAUNCHPADAPI_URL: `${API_URL}:3005`,
    SOCKET_URL: `${API_URL}:3003`,

    FRONT_URL: "http://localhost:3000/",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "",
      APP_STORE:
        "",
    },
    ON_RAMP: {
      API_KEY:
        // 'pk_prod_01H8HS16EX7VPA7BBNNDN03DDH'
        'pk_prod_l3AM9XremfSIgCuFykpgmEQtiwXs7UhlR9yq0wMT4M00'
      // 'pk_test_ass3gtLSWQpI11IWUZLJdrfyQhj7bTw_3xwLvhEvH6Q0'
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/forus",
      twitterUrl: "https://www.twitter.com/forus",
      linkedinUrl: "https://www.linkedin.com/forus",
      redditUrl: "https://www.reddit.com/forus",
      youtubeUrl: "https://www.youtube.com/forus",
    },
    exchangeurl: "http://localhost:3002/home/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "http://localhost:2053/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "",
  };
}

export default key;
