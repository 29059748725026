// import constant
import {
    SET_USER_TRANS_LIST,
} from '../constant';

const initialState = [];

const transData = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER_TRANS_LIST:
            return [
                ...action.data
            ];
        default:
            return state;
    }

}

export default transData;