// import config
import SpotService from "config/spotService";
import UserService from "config/userService";
import WalletService from "../config/walletService"
import axios, { handleResp } from "../config/axios";

// import action
import { setUserSetting } from "./users";

export const getRecentTransaction = async () => {
  try {
    let respData = await WalletService({
      method: "get",
      url: `/api/wallet/recentTransaction`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getLoginHistory = async () => {
  try {
    let respData = await UserService({
      method: "get",
      url: `/api/user/loginHistory`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getNotificationHistory = async () => {
  try {
    let respData = await UserService({
      method: "get",
      url: `/api/user/notificationHistory`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      unread_count: respData.data.unread_count,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getNotificationHistoryAll = async (query) => {
  try {
    let respData = await UserService({
      method: "get",
      url: `/api/user/notificationHistory_dash`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};


export const getNotificationHistory_read = async () => {
  try {
    let respData = await UserService({
      method: "get",
      url: `/api/user/notificationHistory_read`,
    });
    return {
      status: "success",
      loading: false,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};



/**
 * Get User Balance
 */
export const getDashBal = async () => {
  try {
    let respData = await WalletService({
      method: "get",
      url: `/api/wallet/getDashBal`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

/**
 * Update notification
 */
export const editNotif = async (data, dispatch) => {
  try {
    let respData = await UserService({
      method: "put",
      url: `/api/user/editNotif`,
      data,
    });
    dispatch(setUserSetting(respData.data.result));
    // console.log(respData.data.message, 'respDatarespData')
    return {
      status: 'success',
      loading: false,
      message: respData.data.message,
    }
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const gettradeHistory = async () => {
  try {
    let respData = await SpotService({
      method: "get",
      url: `/api/gettradehistory_dash`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,

    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getorderHistory = async () => {
  try {
    let respData = await SpotService({
      method: "get",
      url: `/api/getorderhistory_dash`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};
