// import package
import io from "socket.io-client";
import store from "../store"

// import lib
import config from "./index";

const socketp2p = io(config.P2PAPI_URL);

const createP2pSocketUser = (userId) => {
  // console.log("userIduserId", userId)
  socketp2p.emit("CREATEROOM", userId);
};

socketp2p.on("userAsset", function (data) { });

socketp2p.on("disconnect", (reason) => {
  const { user } = store.getState().auth;
  if (user && user._id)
  createP2pSocketUser(user._id)
});

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export { socketp2p, createP2pSocketUser };
