// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    let nameRegex=/^[a-zA-Z]+$/
    if (isEmpty(value.firstname)) {
        errors.firstname = "First Name Required"
    }else{
    if (!(nameRegex.test(value.firstname))) {
        errors.firstname = "Only Alphabets Allowed"
    }}
    if (isEmpty(value.lastname)) {
        errors.lastname = "Last Name Required"
    }else
    if (!(nameRegex.test(value.lastname))) {
        errors.lastname = "Only Alphabets Allowed"
    }
    if (isEmpty(value.message)) {
        errors.message = "Message Required"
    }
    if (isEmpty(value.email)) {
        errors.email = "Email Required"
    }else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid email"
    }
    
    return errors;
}

export default validation;