import axios from "axios";
import deepParseJson from "../lib/deepParseJson";
import config from './index'

const LaunchpadService = axios.create({
    timeout: 60000,
    baseURL: config.LAUNCHPADAPI_URL
});

LaunchpadService.interceptors.request.use(
    async (config) => {
        const rawPersistData = await localStorage.getItem("user_token");
        const persistData = deepParseJson(rawPersistData);
        if (persistData) {
            config.headers["Authorization"] = `${persistData}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default LaunchpadService;