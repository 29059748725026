// import config
import UserService from 'config/userService';
import axios, { handleResp } from '../config/axios'

// import constant
import {
    SET_USER_KYC,
    SET_ID_PROOF_KYC,
    SET_ADDRESS_PROOF_KYC
} from '../constant';

export const getKycDetail = async (dispatch) => {
    try {
        let respData = await UserService({
            'method': 'get',
            'url': `/api/user/kycdetail`,
        });
        dispatch(setUserKyc(respData.data.result))
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}
export const updateKycDetails = async (data, dispatch) => {
    try {
        let respData = await UserService({
            'method': 'post',
            'url': '/api/user/kycdetail',
            'data': data
        });
        dispatch(setUserKyc(respData.data.result))
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}


export const Kycsumsub = async (data) => {
    try {
        let respData = await UserService({
            'method': 'post',
            'url': `/api/user/kyc_sumsub`,
            data,
        });
            return {
            status: "success",
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
        }
    }
}

export const updateIdProof = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/kyc/idproof`,
            'data': data
        });
        dispatch(setIdProofKyc(respData.data.result))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const updateAddressProof = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/kyc/addressproof`,
            'data': data
        });
        dispatch(setAddressProofKyc(respData.data.result))
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const setUserKyc = (data) => {
    return {
        type: SET_USER_KYC,
        data
    }
}

export const setIdProofKyc = (data) => {
    return {
        type: SET_ID_PROOF_KYC,
        data: {
            'idProof': data.idProof
        }
    }
}

export const setAddressProofKyc = (data) => {
    return {
        type: SET_ADDRESS_PROOF_KYC,
        data: {
            'addressProof': data.addressProof
        }
    }
}
