// import package
import io from "socket.io-client";
import store from "../store"

// import lib
import config from "./index";

const socketuser = io(config.USERAPI_URL);

const createSocketUser = (userId) => {
    socketuser.emit("CREATEROOM", userId);
};

socketuser.on("userAsset", function (data) { });

socketuser.on("disconnect", (reason) => {
    const { user } = store.getState().auth;
    if (user && user._id)
        createSocketUser(user._id)

});


// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export { socketuser, createSocketUser };
