// import lib
import currency from 'reducers/currency';
import isEmpty from './isEmpty';

export const toFixed = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      return item.toFixed(type)
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const currencyFormat = (item) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = item.toString();
      let splitValue = item.split('.')
      return splitValue[1] ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${splitValue[1]}` : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return ''
  } catch (err) {
    return ''
  }
}
/*
export const toFixedDown = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)")
      let m = item.toString().match(decReg);
      return m ? parseFloat(m[1]) : item.valueOf();
    }
    return ''
  } catch (err) {
    return ''
  }
}
*/


export const toFixedDown = (item, type = 2) => {
  try {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (type || -1) + '})?');
    return item.toString().match(re)[0];
  }
  catch (err) {
    return ''
  }
}

Number.prototype.noExponents = function () {
  let data = String(this).split(/[eE]/);

  if (data.length == 1) return data[0];

  let z = '',
    sign = this < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

export const longDecimals = (item) => {
  if (isEmpty(item)) return 0
  if (item.toString().split(/[eE]/)[1]) {
    return item.noExponents()
  }
  return item
}


export const truncateDecimals = (num, decimals) => {
  num = convert(num)
  let
    s = num.toString()
    , p = s.indexOf('.')
    ;
  s += (p < 0 ? (p = 1 + s.length, '.') : '') + '0'.repeat(decimals)
  return s.slice(0, p + 1 + decimals)
}

export const convert = (n) => {
  try {
    var sign = +n < 0 ? '-' : '',
      toStr = n.toString()
    if (!/e/i.test(toStr)) {
      return n
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, '')
      .replace(/^([0-9]+)(e.*)/, '$1.$2')
      .split(/e|\./)
    return +pow < 0
      ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + '.' + decimal.slice(+pow))
  } catch (err) {
    return 0
  }
}
