// import config
import axios, { handleResp } from "../config/axios";
import config from "./config";
// import constant
import {
  SET_USER_WALLET_LIST,
  SET_USER_FIRST_CURRENCY,
  SET_USER_SECOND_CURRENCY,
  UPDATE_USER_WALLET_STAKE,
  SET_USER_TRANS_LIST,
  SET_TOKEN_FEE,
} from "../constant";
import WalletService from "config/walletService";
import LaunchpadService from "../config/LaunchpadService"
export const getAssetData = async (dispatch) => {
  try {
    let respData = await WalletService({
      method: "get",
      url: `/api/wallet/getAssetsDetails`,
    });
    dispatch(userTransList(respData.data.result.TransData))
    dispatch(userWalletList(respData.data.result.walletData));
    return true;
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const updateWallet = async (dispatch, data, callFrom) => {
  if (callFrom == "stake") {
    dispatch({
      type: UPDATE_USER_WALLET_STAKE,
      data,
    });
  }
  return false;
};

export const userWalletList = (data) => {
  return {
    type: SET_USER_WALLET_LIST,
    data,
  };
};

export const userTransList = (data) => {
  // console.log(data, '99datadatadata')
  return {
    type: SET_USER_TRANS_LIST,
    data,
  };
};

export const withdrawRequestFiat = async (data) => {
  try {
    let respData = await WalletService({
      method: "post",
      url: '/api/wallet/fiatWithdraw',
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const fiatRequestVerify = async (data) => {
  try {
    let respData = await WalletService({
      method: "patch",
      url: `/api/wallet/fiatWithdraw`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const withdrawRequestCoin = async (data) => {
  try {
    let respData = await WalletService({
      method: "post",
      url: '/api/wallet/coinWithdraw',
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const coinRequestVerify = async (data) => {
  try {
    let respData = await WalletService({
      method: "patch",
      url: `/api/wallet/coinWithdraw`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const fiatDepositRequest = async (data) => {
  try {
    let respData = await WalletService({
      method: "post",
      url: '/api/wallet/fiatDeposit',
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const walletTransfer = async (data) => {
  try {
    let respData = await WalletService({
      method: "post",
      url: `/api/wallet/walletTransfer`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getAssetByCurrency = async (currencyId) => {
  try {
    // console.log(currencyId,'----98-currencyId')
    let respData = await WalletService({
      method: "get",
      url: `/api/wallet/getAsset/` + currencyId,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const setUserFirstCurrency = (assetData, dispatch) => {
  dispatch({
    type: SET_USER_FIRST_CURRENCY,
    data: assetData,
  });
};

export const setUserSecondCurrency = (assetData, dispatch) => {
  dispatch({
    type: SET_USER_SECOND_CURRENCY,
    data: assetData,
  });
};

export const getTrnxHistory = async (params, query) => {
  try {
    let respData = await WalletService({
      method: "get",
      url: `/api/wallet/txn-history/` + params,
      params: query,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getLaunchpadhistory = async (query) => {
  try {
    let respData = await LaunchpadService({
      method: "get",
      url: `/api/launchpad/history/launchpadhistory`,
      params: query,
    });

    // console.log("respDatarespDatarespData", respData);
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getUserDeposit = async () => {
  try {
    await WalletService({
      method: "get",
      url: '/api/wallet/userDeposit',
    });
    return { status: "success", loading: false };
  } catch (err) {
    handleResp(err, "error");
    return { status: "failed", loading: false };
  }
};

export const setTokenfee = (assetData, dispatch) => {
  dispatch({
    type: SET_TOKEN_FEE,
    data: assetData,
  });
};


export const buyCryptoExchange = async (data) => {
  try {
    let respData = await WalletService({
      method: "post",
      url: `/api/wallet/cryptoexchange`,
      data
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};



export const getMyAirdrop = async (params, query) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/history/airdrop/` + params,
      params: query,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};


