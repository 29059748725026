// import package
import io from "socket.io-client";
import store from "../store"

// import lib
import config from "./index";

const socketspot = io(config.SPOTAPI_URL);

const createSocketSpot = (userId) => {
  socketspot.emit("CREATEROOM", userId);
};

socketspot.on("userAsset", function (data) { });


socketspot.on("disconnect", (reason) => {
  const { user } = store.getState().auth;
  if (user && user._id)
  createSocketSpot(user._id)
});

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export { socketspot, createSocketSpot };
