// import package
import io from "socket.io-client";
import store from "../store"

// import lib
import config from "./index";

const socketlaunch = io(config.LAUNCHPADAPI_URL);

const createLaunchSocketUser = (userId) => {
  // console.log("userIduserId", userId)
  socketlaunch.emit("CREATEROOM", userId);
};

socketlaunch.on("userAsset", function (data) { });


socketlaunch.on("disconnect", (reason) => {
  const { user } = store.getState().auth;
  if (user && user._id)
    createLaunchSocketUser(user._id)
});

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export { socketlaunch, createLaunchSocketUser };
