// import config
import axios, { handleResp } from "../config/axios";

// import constant
import { SET_P2P_SPOT_PAIR_TRADE } from "../constant";
import P2PService from "../config/P2PService"
import UserService from "../config/userService"

export const getP2PPairList = async () => {
  try {
    let respData = await P2PService({
      method: "get",
      url: `/api/p2p/p2pTradePair`,
    });
    // console.log("respData.data.resultp2p---", respData.data.result);

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const postTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/postTrade`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

// export const getMyaddData = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/p2pMyadddetails`,
//         });
//         return {
//             status: 'success',
//             loading: false,
//             result: respData.data.result,
//         }
//     }
//     catch (err) {
// handleResp(err, 'error')
//         return {
//             status: 'failed',
//             loading: false,
//         }
//     }
// }

export const getMyaddData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/p2pMyadddetails`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getMyTransactions = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMyTransactions`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMyP2PHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMyTransactions`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMyRecentaddData = async (data, query) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/p2pMyrecentadddetails`,
      data,
      params: query
    });
    // console.log("My ad data---", respData.data.result);

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      count: respData.data.count,
      result: respData.data.result,
      data: respData.data.data,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const updateTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/updateTrade`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const cancelTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/cancelTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const disputeTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/disputeTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getBuyAdData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getBuyAdDetails`,
      data,
    });
    // console.log("respData----", respData.data.result);
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSellAdData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getSellAdDetails`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSarchBuyData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getSarchBuyData`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSarchSellData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getSarchSellData`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const buyP2PTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/buyP2PTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Resp---", err);

    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const buyConfirmP2PTrade = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/buyConfirmP2PTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSingleBuyAdDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSingleBuyAdDetails`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSingleOrderDetails = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getSingleOrderDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getOrderStatus = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getOrderStatus`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getChatDetails = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getChatDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const saveChatData = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/saveChatDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const confirmPay = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/confirmPay`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const releaseCryptocurrency = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/releaseCryptocurrency`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};


export const feedBackRating = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/feedBackRating`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const UpdateRating = async (data) => {
  try {
    let respData = await P2PService({
      method: "put",
      url: `/api/p2p/feedBackRating`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};



export const getFeedBack = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getFeedBack`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};


export const FeedbackCount = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getfeedbackcount`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};


export const check2FA = async () => {
  try {
    let respData = await P2PService({
      method: "get",
      url: `/api/user/check2FA`,
    });
    return {
      status: respData.data.success,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---------", err);
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const cancelMyadAction = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/cancelMyad`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const closeTradeAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/closeOrder`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const closeTradeDateCrossed = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/closeTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTradeData = async (data, query) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getTradeDetails`,
      'params': query,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getChatlisthistory = async (data) => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/getChatlisthistory`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};


export const Unreadchat = async () => {
  try {
    let respData = await P2PService({
      method: "post",
      url: `/api/p2p/p2pchatRead`,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };

  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
}

export const updatevacation = async (data) => {
  try {
    let respData = await UserService({
      method: "post",
      url: `/api/user/updatevacation`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getUserInfo = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/getuserinfo`,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      message: err.response.data.message,
      loading: false,
    };
  }
};

