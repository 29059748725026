import jwt_decode from "jwt-decode";

// import constant
import { SET_AUTHENTICATION } from "../constant";

// import config
import { createSocketSpot } from "../config/spotSocket";
import { createP2pSocketUser } from "../config/p2pSocket";
import { createSocketUser } from "../config/userSocket"
import { createLaunchSocketUser } from "../config/launchpadsocket";

export const decodeJwt = (token, dispatch) => {
  if (token) {
    token = token.replace("Bearer ", "");
    const decoded = jwt_decode(token);
    if (decoded) {
      createSocketSpot(decoded._id);
      createP2pSocketUser(decoded._id);
      createSocketUser(decoded._id)
      createLaunchSocketUser(decoded._id)
      dispatch({
        type: SET_AUTHENTICATION,
        authData: {
          isAuth: true,
          userId: decoded._id,
        },
      });
    }
  }
};
